var site = site || {};
var generic = generic || {};

(function ($) {
  Drupal.behaviors.thGnavAccountFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      var $template = $('.js-gnav-account-formatter', context);
      var $newMemberLink = $('.js-new-member-link', $template);
      var $signinForm = $(
        '.js-gnav-account-content-container-forms-signin-container-form',
        $template
      );
      if ($signinForm.length > 0) {
        $signinForm.append($newMemberLink);
        $newMemberLink.removeClass('hidden');
      }
      $('.js-facebook_disclaimer').attr('checked', 'checked');
    }
  };
})(jQuery);
